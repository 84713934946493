// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-30-dmc-js": () => import("./../../../src/pages/30dmc.js" /* webpackChunkName: "component---src-pages-30-dmc-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alberta-js": () => import("./../../../src/pages/alberta.js" /* webpackChunkName: "component---src-pages-alberta-js" */),
  "component---src-pages-cartograms-js": () => import("./../../../src/pages/cartograms.js" /* webpackChunkName: "component---src-pages-cartograms-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-elections-js": () => import("./../../../src/pages/elections.js" /* webpackChunkName: "component---src-pages-elections-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-eurovision-js": () => import("./../../../src/pages/eurovision.js" /* webpackChunkName: "component---src-pages-eurovision-js" */),
  "component---src-pages-fantasy-js": () => import("./../../../src/pages/fantasy.js" /* webpackChunkName: "component---src-pages-fantasy-js" */),
  "component---src-pages-freelance-js": () => import("./../../../src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nc-2020-js": () => import("./../../../src/pages/nc_2020.js" /* webpackChunkName: "component---src-pages-nc-2020-js" */),
  "component---src-pages-nc-js": () => import("./../../../src/pages/nc.js" /* webpackChunkName: "component---src-pages-nc-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-rm-js": () => import("./../../../src/pages/rm.js" /* webpackChunkName: "component---src-pages-rm-js" */),
  "component---src-pages-sandbox-js": () => import("./../../../src/pages/sandbox.js" /* webpackChunkName: "component---src-pages-sandbox-js" */),
  "component---src-pages-saskatchewan-js": () => import("./../../../src/pages/saskatchewan.js" /* webpackChunkName: "component---src-pages-saskatchewan-js" */),
  "component---src-pages-signs-js": () => import("./../../../src/pages/signs.js" /* webpackChunkName: "component---src-pages-signs-js" */),
  "component---src-pages-states-js": () => import("./../../../src/pages/states.js" /* webpackChunkName: "component---src-pages-states-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thematic-js": () => import("./../../../src/pages/thematic.js" /* webpackChunkName: "component---src-pages-thematic-js" */),
  "component---src-pages-tomorrow-js": () => import("./../../../src/pages/tomorrow.js" /* webpackChunkName: "component---src-pages-tomorrow-js" */),
  "component---src-pages-visit-js": () => import("./../../../src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */)
}

